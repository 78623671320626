<template>
    <v-container class="px-5">
        <div class="px-5" v-if="isAccountComplete">
            <v-row>
                <v-col cols="6"></v-col>
                <v-col cols="6">
                    <div class="float-right">
                        <v-btn color="green white--text" tile v-if="page==='account'" class="ml-4 py-6" @click.prevent="showAccountModal=true">
                            account update request
                        </v-btn>
                        <v-btn color="blue white--text" tile v-else class="ml-4 py-6" @click.prevent="showLoanModal=true">
                           Loan application request
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="12" md="6" style="padding-bottom: -30px !important;">
                    <div class="text-h5 font-weight-bold">{{page==='loan'?"Requested Loans":"Account Update"}}</div>
                </v-col>
                <v-col sm="12" md="6">
                    <div class="float-right">
                        <v-tabs v-model="tabs" class="tabs-container">
                            <v-tab v-for="item in [{name: 'Loan Requests', value: 'loan'}, {name: 'Account Requests', value: 'account'}]" :key="item.name" @click="page=item.value">
                                <div class="text-uppercase">{{item.name}}</div>
                            </v-tab>
                            <v-tabs-slider color="primary"></v-tabs-slider>
                        </v-tabs>
                    </div>
                </v-col>
            </v-row>
            <loan-requests :show="page==='loan'"/>
            <account-requests :show="page==='account'" :show-account-modal="showAccountModal" @dismissAccountModal="showAccountModal=false"/>
            <request-modal :show="showLoanModal" @dismiss="showLoanModal=false" @onSubmitRequest="getLoanRequests"/>
        </div>
        <v-row v-else justify="center" align="center" style="height: 550px">
            <v-col cols="6">
                <div class="text-center">
                    <div class="text-h5 font-weight-bold">
                        Access Denied !!
                    </div>
                    <div class="my-4">You have not updated your loan account, kindly go back to loan page and update your account</div>
                    <v-btn class="pa-6" color="primary" tile @click="$router.push({name: 'Loan'})">Go Back To Loans</v-btn>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import LoanRequests from "./components/LoanRequests";
    import AccountRequests from "./components/AccountRequests";
    import RequestModal from "../components/RequestModal";
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        components: {RequestModal, AccountRequests, LoanRequests},
        data: ()=>({
            tabs: null,
            page: 'loan',
            showAccountModal: false,
            showLoanModal: false,
            isAccountComplete: true,
        }),
        methods: {
            ...mapActions('loan', ['userLoanRequests', 'fetchLoanAccount', 'fetchGuarantors']),
            ...mapMutations('loan', ['storeLoanAccount']),
            getLoanRequests() {
                const data = {
                    owner_id: this.user.id,
                    search: "",
                    end: "",
                    start: "",
                    page: 1
                };
                this.userLoanRequests(data);
            },
            getLoanAccount() {
                this.fetchLoanAccount(this.user.id).then(res => {
                    this.storeLoanAccount(res.data);
                    this.isAccountComplete = res.data.is_completed;
                }).catch(err => {
                    //this.$toast.error(err.response.data.message);
                })
            },
        },
        computed: {
            ...mapGetters('auth', ['user']),
            ...mapGetters('loan', ['loanAccount', 'guarantors']),
        },
        created() {
            this.page = 'loan';
            this.fetchGuarantors(this.user.id);
            this.getLoanAccount();
        },

    }
</script>

<style scoped>

</style>