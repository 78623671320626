var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',[_c('v-row',{},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"search","clearable":"","placeholder":"search by amount, duration","prepend-inner-icon":"mdi-magnify","outlined":"","dense":""},on:{"input":function($event){_vm.isTyping = true},"click:clear":function($event){_vm.search = ''}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.startTimeFormat,"clearable":"","label":"Start","readonly":"","dense":"","outlined":"","append-icon":"mdi-calendar"},on:{"click:clear":function($event){_vm.start_time = null}}},'v-text-field',attrs,false),on))]}}],null,false,3181899352),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{on:{"change":function($event){_vm.menu1 = false}},model:{value:(_vm.start_time),callback:function ($$v) {_vm.start_time=$$v},expression:"start_time"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.endTimeFormat,"clearable":"","label":"End","readonly":"","outlined":"","dense":"","append-icon":"mdi-calendar"},on:{"click:clear":function($event){_vm.end_time = null}}},'v-text-field',attrs,false),on))]}}],null,false,4134812407),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"change":function($event){_vm.menu2 = false}},model:{value:(_vm.end_time),callback:function ($$v) {_vm.end_time=$$v},expression:"end_time"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"float-right"},[_c('v-btn',{staticClass:"px-md-5 py-4",attrs:{"color":"secondary accent-4"},on:{"click":function($event){$event.preventDefault();return _vm.exportXLSX.apply(null, arguments)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-download")]),_vm._v(" Download ")],1)],1)])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.loanRequests,"disable-pagination":"","hide-default-footer":true},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.loanRequests.indexOf(item) + 1)+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("floatNairaFormat")(item.amount)))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('truncate-word',{attrs:{"text":item.description,"length":30}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("dashboardDateFormat")(item.created_at)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.status===0?'pending':item.status===1?'viewed':item.status===2?'approved':'rejected'))])]}}],null,true)}),(_vm.loanRequests.length)?_c('div',{staticClass:"float-right mt-3"},[_c('v-pagination',{attrs:{"length":_vm.getCount(),"total-visible":"7"},on:{"input":_vm.getLoanRequests},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }