<template>
    <v-dialog
            v-model="show"
            persistent
            max-width="500px"
    >
        <v-card class="pa-lg-10 pa-sm-5">
            <v-app-bar
                    height="30"
                    color="transparent"
                    class="pa-0 mb-md-5"
                    flat
            >
                <v-spacer/>
                <v-app-bar-title class="secondary--text modal-title">
                    Tran<span class="primary--text">Save</span>
                </v-app-bar-title>
                <v-spacer/>
                <v-btn icon @click="$emit('dismiss')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-app-bar>
            <div class="mb-sm-2 mb-md-4">Send request to the admin to enable you edit your loan account details</div>
            <v-form @submit.prevent="onFormSubmit" v-model="isValid" lazy-validation ref="form">
                <v-text-field
                        v-model="form.title"
                        :rules="[v=>!!v || 'title required']"
                        @keydown="form.clear()"
                        outlined
                        label="Title"
                        placeholder="enter request title"
                />
                <v-textarea
                        v-model="form.content"
                        auto-grow
                        outlined
                        :rules="[v=>!!v || 'content is required']"
                        label="Content"
                        placeholder="enter request content"
                        rows="1"
                ></v-textarea>

                <v-btn
                        block
                        tile
                        color="primary darken-1"
                        class="py-7"
                        type="submit"
                >
                    <v-progress-circular v-if="processing" indeterminate color="secondary" class="mr-3" width="5"/>
                    Send Request
                </v-btn>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    export default {
        props: ["show"],
        data: () =>({
            isValid: true,
            processing: false,
            form: new Form({
                title: '',
                content: '',
            }),
        }),
        methods: {
            ...mapActions('loan', ['saveUserRequest', 'userAccountRequests']),
            onFormSubmit() {
                if (this.$refs.form.validate()) {
                    this.processing = true;
                    this.form.owner_id = this.user.id;
                    this.form.request_type = 'Loan Account Update';
                    this.saveUserRequest(this.form).then(res => {
                        this.$toast.success('request sent to admin successfully');
                        this.getUserRequests();
                        this.$emit('dismiss');
                    }).catch(err => {
                        this.$toast.error(err.response.data.message);
                    }).finally(() => {
                        this.processing = false;
                    })
                }
            },
            getUserRequests() {
                const data = {
                    owner_id: this.user.id,
                    search: "",
                    end: "",
                    start: "",
                    page: 1
                };
                this.userAccountRequests(data);
            }
        },
        computed: {
            ...mapGetters('auth', ['user']),
        },
        created() {
            this.form.clear();
            this.form.reset();
        }

    }
</script>

<style scoped>

</style>