<template>
    <div v-if="show">
        <v-row class="">
            <v-col cols="4">
                <v-text-field
                        v-model="search"
                        label="search"
                        clearable
                        placeholder="search by amount, duration"
                        @input="isTyping = true"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        @click:clear="search = ''"
                        dense
                />
            </v-col>
            <v-col cols="3">
                <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        max-width="290"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                :value="startTimeFormat"
                                clearable
                                label="Start"
                                readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="start_time = null"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                            v-model="start_time"
                            @change="menu1 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="3">
                <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        max-width="290"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                :value="endTimeFormat"
                                clearable
                                label="End"
                                readonly
                                outlined
                                dense
                                append-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="end_time = null"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                            v-model="end_time"
                            @change="menu2 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="2">
                <div class="float-right">
                    <v-btn
                            color="secondary accent-4"
                            class="px-md-5 py-4"
                            @click.prevent="exportXLSX"
                    >
                        <v-icon class="mr-2">mdi-download</v-icon>
                        Download
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <v-data-table
                :headers="headers"
                :items="loanRequests"
                disable-pagination
                :hide-default-footer="true"
        >
            <template v-slot:[`item.index`]="{ item }">
                {{ loanRequests.indexOf(item) + 1 }}
            </template>
            <template v-slot:[`item.amount`]="{ item }">
                <div>{{item.amount | floatNairaFormat}}</div>
            </template>
            <template v-slot:[`item.description`]="{ item }">
                <truncate-word :text="item.description" :length="30"/>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
                <div>{{ item.created_at | dashboardDateFormat }}</div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
               <div>{{item.status===0?'pending':item.status===1?'viewed':item.status===2?'approved':'rejected'}}</div>
            </template>
        </v-data-table>
        <div class="float-right mt-3" v-if="loanRequests.length">
            <v-pagination
                    v-model="page"
                    :length="getCount()"
                    total-visible="7"
                    @input="getLoanRequests"
            ></v-pagination>
        </div>
    </div>
</template>


<script>
    import XLSX from "xlsx";
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        components: {},
        props: {
            show: {
                required: true,
                type: Boolean,
                default: false,
            }
        },
        data: ()=>({
            search: '',
            menu1: false,
            menu2: false,
            start_time: null,
            end_time: null,
            isTyping: false,
            headers: [
                {text: 'S/No', value: 'index', align: 'start', sortable: false},
                {text: 'Amount', value: 'amount'},
                {text: 'Duration(days)', value: 'duration'},
                {text: 'Description', value: 'description'},
                {text: 'Date Applied', value: 'created_at'},
                {text: 'Status', value: 'status'},
            ],
            page: 1,
        }),
        methods:{
            ...mapActions('loan', ['userLoanRequests']),
            getLoanRequests() {
                const data = {
                    owner_id: this.user.id,
                    search: this.search !== null? this.search : "",
                    end: this.end_time !== null? this.end_time : "",
                    start: this.start_time !== null? this.start_time : "",
                    page: this.page
                };
                this.userLoanRequests(data);
            },
            getCount() {
                return Math.ceil(this.loanRequestCount / 10);
            },
            exportXLSX() {
                const data = XLSX.utils.json_to_sheet(this.getExcelData());
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, data, 'data');
                const date = new Date();
                const filename =
                    "report_" +
                    date.getFullYear() +
                    ("0" + (date.getMonth() + 1)).slice(-2) +
                    ("0" + date.getDate()).slice(-2) +
                    ("0" + date.getHours()).slice(-2) +
                    ("0" + date.getMinutes()).slice(-2) +
                    ("0" + date.getSeconds()).slice(-2) +
                    ".xlsx";
                XLSX.writeFile(wb, filename);
            },
            getExcelData() {
                let data = [];
                this.loanRequests.map(item => {
                    let obj = {};
                    obj.Name = item.owner.name;
                    obj.Phone = item.owner.phone;
                    obj.Amount = item.amount;
                    obj.Status = item.status? 'Approved' : 'Declined';
                    obj.Duration = item.duration;
                    obj.Date_Created = moment(String(item.created_at)).format("DD/MM/YYYY hh:mma");

                    data.push(obj);
                });
                return data;
            },

            defaultStates() {
                this.search = '';
                this. start_time = null; this.end_time = null;
            }
        },
        computed: {
            startTimeFormat() {
                return this.start_time ? moment(this.start_time).format('Do MMM [,] YYYY') : ''
            },
            endTimeFormat() {
                return this.end_time ? moment(this.end_time).format('Do MMM [,] YYYY') : ''
            },
            ...mapGetters('loan', ['loanRequests', 'loanRequestCount']),
            ...mapGetters('auth', ['user']),
        },
        mounted() {
            this.defaultStates();
            this.getLoanRequests();
        },
        watch: {
            search: _.debounce(function() {
                this.isTyping = false;
            }, 1000),
            isTyping: function(value) {
                if (!value) {
                    this.getLoanRequests();
                }
            },
            start_time(newVal, oldVal) {
                if(newVal !== null && newVal !== oldVal) {
                    this.getLoanRequests();
                }
            },
            end_time(newVal, oldVal) {
                if(newVal !== null && newVal !== oldVal) {
                    this.getLoanRequests();
                }
            },
        }
    }
</script>

<style scoped>

</style>